<template>
    <vue-terminal></vue-terminal>
</template>

<script>
import VueTerminal from './components/terminal/VueTerminal.vue'

export default {
  name: 'App',
  components: {
    VueTerminal
  }
}
</script>

<style>
html, body, #app {
  margin: 0;
  height: 100%;
  min-height: 100%;
  background-color: #111;
}
</style>
