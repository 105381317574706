<template>
  <div>
      {{typing}}
  </div>
</template>

<script>

export default {
  name: 'SlowType',
  data: function () {
    return {
        typing: ''
    }
  },
  props: [
    'text'
  ],
  methods: {
    showText: function (message, index, interval) {   
        let newInterval = interval;
        if (index < message.length) {
            if (message[index] === '.') {
                newInterval = interval * 10;
            }
            this.typing = this.typing + message[index++];
            setTimeout(() => { 
                this.showText(message, index, interval); 
            }, newInterval);
        }
    }
  },
  mounted () {
    this.showText(this.text, 0, 70);   
  }
}
</script>

<style lang="css">
</style>