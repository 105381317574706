<template>
  <div class="vue-terminal-wrapper">
    <div class="lds-css" v-if="this.waiting">
      <div style="width:100%;height:100%" class="lds-rolling"><div>
      </div>
    </div>
    </div>
      <div id="terminal"
          class="fallout fullscreen-height"
          data-theme="fallout">
        <div class="content">
          <div v-for="message in history" :key="message">
            <div class="cmd_in" v-if="message.cmd_in.length > 0">
              <span class="cmd_ps">$ </span>
              {{message.cmd_in}}
            </div>
            <slow-type 
              class="cmd_out"
              v-bind:text="message.cmd_out">
            </slow-type>
          </div>
        </div>
        <div class="prompt">
          <input class="input show-caret" 
            data-ps="$ "
            v-on:keyup.enter="sendCommand"
            v-on:input="onInput"
            v-model="command" />
        </div>
      </div>
  </div>
</template>

<script>
import SlowType from '../slow-type/SlowType.vue'
import LexBot from './lexbot'

let lexbot = new LexBot()
export default {
  name: 'VueTerminal',
  data: function () {
    return {
      waiting: false,
      history: [],
      command: '$ '
    }
  },
  props: {},
  methods: {
    onInput ($event) {
      if (this.waiting) {
        $event.target.value = this.command;
        return;
      }
      if ($event.target.value.length < 2) {
        $event.target.value = '$ ';
        return;
      }
      this.command = $event.target.value
    },
    addHistory (cmd_in, cmd_out) {
      this.history.push({
        cmd_in,cmd_out
      })
    },
    sendCommand () {
      let command = this.command.slice(2);
      if (command.length === 0) {
        return;
      }
      this.toggleWaiting()
      return lexbot.pushMessage(command)
        .then((response) => {
          this.addHistory(command, response);
          this.command = '$ '
          this.toggleWaiting()
          return response
        });
    },
    toggleWaiting () {
      this.waiting = !this.waiting
    }
  },
  mounted () {
    lexbot.startSession().then((message) => {
      console.log(message);
      this.addHistory('',message);
    })
  },
  components: {
    SlowType
  }
}
</script>

<style lang="css">
/* hight settings */
#terminal.default-height { max-height: 250px;}
#terminal.fullscreen-height { height: 100vh;}


/* fallout theme */
.fallout {
    background: rgba(91,107,77,1);
    /* All the single browsers */
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg=='), -moz-radial-gradient(center, ellipse cover, rgba(91,107,77,1) 0%, rgba(23,28,18,1) 100%) no-repeat;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg=='), -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(91,107,77,1)), color-stop(100%, rgba(23,28,18,1)));
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg=='), -webkit-radial-gradient(center, ellipse cover, rgba(91,107,77,1) 0%, rgba(23,28,18,1) 100%) no-repeat;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg=='), -o-radial-gradient(center, ellipse cover, rgba(91,107,77,1) 0%, rgba(23,28,18,1) 100%) no-repeat;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg=='), -ms-radial-gradient(center, ellipse cover, rgba(91,107,77,1) 0%, rgba(23,28,18,1) 100%) no-repeat;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg=='), radial-gradient(ellipse at center, rgba(91,107,77,1) 0%, rgba(23,28,18,1) 100%) no-repeat;
}

.fallout .content { padding: 15px 15px 0 15px; }
.fallout .prompt { padding: 0 15px 15px 15px; }
.fallout .content, .fallout .prompt,
.fallout .prompt .input,
.fallout .prompt .input::before {
    color: transparent; text-shadow: 0 0 0 #90d690; /* Hack to hide the real caret */
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    font-family: VT323, monospace, "Lucida Console", Monaco;
    letter-spacing: 1px;
    outline: 0;
}
.fallout .prompt .input.show-caret{ color: #90d690; opacity: .85; }
.fallout .prompt .input::after { font-size: 22px; }
.fallout .loading::after { content: "âš™"; color: #ddd; font-size: 10em; border-radius: 10em; opacity: 0.4; }
.fallout .content ul { margin: 0; }
.fallout .content ul li { list-style-type: none; }


/* other styles */
#terminal{ position: relative; display: block; overflow-X: hidden; height: 100%;text-align: left; }
 #terminal div.content div p{ margin: 0; }
 #terminal div.content div{ clear: both;  word-wrap:break-word; }
 #terminal div.content div ul{ padding: 0; white-space: normal }
 #terminal div.content div ul li{ list-style: none; }
 #terminal div.content div ul.sq-li li{ display: inline-block; text-align: center; padding: 10px; min-width: 5%; }
 #terminal div.prompt input{
    margin: 0;
    padding: 0;
    width: 100%;
    white-space:pre-wrap;
    word-wrap:break-word;
    cursor: default;
    outline: none;
    background-color: transparent;
    border: none;
  }
 #terminal div.prompt input::before{ vertical-align: middle; content: attr(data-ps); }
 #terminal div.prompt input::after{ visibility : visible; vertical-align: middle; content: attr(data-caret); }
 #terminal div.prompt input.blink::after{ visibility : hidden; }
 #terminal div.prompt .hide{ position:absolute; top: -9999em; }
 #terminal div.loading{ display: none; }
 #terminal div.loading.working{ display: block; display:flex; justify-content: center; align-items: center;position: fixed;  width: inherit; height: inherit; }
 #terminal div.loading span{ -webkit-animation: spin 4s linear infinite; -moz-animation: spin 4s linear infinite; -ms-animation: spin 4s linear infinite; -o-animation: spin 4s linear infinite; animation: spin 4s linear infinite; }@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }@-ms-keyframes spin { 100% { -ms-transform: rotate(360deg); } }@-o-keyframes spin { 100% { -o-transform: rotate(360deg); } }@keyframes spin { 100% { transform: rotate(360deg); } }

/* loader */
@keyframes lds-rolling {
 0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.lds-css {
  position: absolute;
}
.lds-rolling {
   position: fixed;
    top: 30px;
    z-index: 1000;
    right: 10px;
}
.lds-rolling div,
.lds-rolling div:after {
  position: absolute;
  width: 160px;
  height: 160px;
  border: 20px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.lds-rolling div {
  -webkit-animation: lds-rolling 1s linear infinite;
  animation: lds-rolling 1s linear infinite;
  top: 100px;
  left: 100px;
}
.lds-rolling div:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.lds-rolling {
  width: 36px !important;
  height: 36px !important;
  -webkit-transform: translate(-18px, -18px) scale(0.18) translate(18px, 18px);
  transform: translate(-18px, -18px) scale(0.18) translate(18px, 18px);
}
</style>