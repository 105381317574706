import * as AWS from "aws-sdk";
import config from "../../config.json";

AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
// Provide your Pool Id here
    IdentityPoolId: config.cognito_identity_pool_id,
});

var lexruntime = new AWS.LexRuntime();
var lexUserId = 'ericpeach-' + Date.now();

const default_params = {
    botAlias: 'EricPeachDev',
    botName: 'EricPeach',
    userId: lexUserId
};
let sessionAttributes = {
    IdentityId: AWS.config.credentials.params.IdentityId
};
export default class LexBot {
    startSession () {
        return this.pushMessage('hello');
    }

    pushMessage (message) {
        let params = Object.assign({
            inputText: message,
            sessionAttributes
        }, default_params);

        return new Promise((resolve, reject) => {
            lexruntime.postText(params, function(err, data) {
                if (err) {
                    console.log(err, err.stack);
                    reject(err);
                }
                if (data) {
                    sessionAttributes = data.sessionAttributes;
                    // show response and/or error/dialog status
                    console.log(data);
                    resolve(data.message);
                }
            });
        });
    }
}

